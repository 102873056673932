<template>
  <v-card min-height="100vh" flat color="transparent" class="xw-full xp-[1em]">
    <v-btn
      text
      depressed
      class="text-none xtext-[#9A9A9A] xtext-[14px] xleading-[17px]"
      @click.stop="goBack"
    >
      <v-icon left>mdi-chevron-left</v-icon> Go back
    </v-btn>
    <div
      :class="[mdUp ? 'xh-[100px]' : '']"
      class="xgap-1 xmt-[2px] xmb-[1em] xgrid md:xgrid-cols-4 xgrid-cols-1 xpy-[1em] xjustify-center xitems-center xbg-[#fff] xrounded-[10px]"
      v-if="selectedUser"
    >
      <div
        class="md:xcol-span-2 xcol-span-1 xflex xflex-col md:xpx-[2em] xjustify-start xitems-stretch"
      >
        <h3
          class="xmb-[1em] xfont-[400] xtext-[16px] xleading-[20px] xtext-[#CECECE]"
        >
          Member Name
        </h3>
        <avatar
          class="xfont-[600] xtext-[30px] xleading-[37px]"
          :user="selectedUser"
          with-name
        ></avatar>
      </div>
      <div class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch">
        <h3
          class="xmb-[1em] xfont-[400] xtext-[16px] xleading-[20px] xtext-[#CECECE]"
        >
          Email Address
        </h3>
        <h1 class="xtext-[18px] xleading-[36.5px]">{{ selectedUser.email }}</h1>
      </div>
      <div class="xcol-span-1 xflex xflex-col xjustify-start xitems-stretch">
        <h3
          class="xmb-[1em] xfont-[400] xtext-[16px] xleading-[20px] xtext-[#CECECE]"
        >
          Status
        </h3>
        <chip-in
          class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
          dots
          v-if="selectedUser.clocking_status.on_work"
          label
          text="In"
        ></chip-in>
        <chip-brb
          class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
          dots
          v-else-if="selectedUser.clocking_status.on_break"
          label
          text="Brb"
        ></chip-brb>
        <chip-out
          class="xflex xjustify-center xflex-col xw-fit xpx-[1.5em]"
          dots
          v-else-if="selectedUser.clocking_status.on_out"
          label
          text="Out"
        ></chip-out>
      </div>
    </div>
    <app-tabs :tabs="tabs" optional>
      <router-view :key="$route.fullPath"></router-view>
    </app-tabs>
  </v-card>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedUser: null,
      loading: false,
      userId: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters('workspace', ['allusers']),
    tabs() {
      return [
        {
          name: "Time Spent",
          to: {
            name: "app-user-time-monthly",
            params: {
              pid: this.$route.params.pid,
              user_id: this.$route.params.user_id,
              year_month: moment().format("YYYY-MM"),
            },
          },
          alias: ["app-user-time-monthly", "app-user-time-custom"],
        },
        {
          name: "PTO's",
          to: {
            name: "app-user-pto-monthly",
            params: {
              pid: this.$route.params.pid,
              user_id: this.$route.params.user_id,
              year_month: moment().format("YYYY-MM"),
            },
          },
          alias: ["app-user-pto-custom", "app-user-pto-monthly"],
        },
      ];
    },
  },
  watch: {
    "$route.params.user_id": {
      handler: function (val) {
        this.userId = val;
        if (val) {
          this.fetchUser(val);
          this.fetchUserProjects(val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.allusers.length <= 0) {
      this.fetchWorkspaceAllUsers(this.user.team_id);
    }
  },
  methods: {
    ...mapActions("workspace", ["fetchWorkspaceAllUsers"]),
    ...mapActions("projects", ["fetchUserProjects"]),
    fetchUser(id) {
      this.loading = true;
      this.$axios
        .get(`api/workspaces/${this.user.team_id}/users/${id}`)
        .then(({ data }) => {
          this.selectedUser = data;
        })
        .finally(() => (this.loading = false));
    },
    goBack() {
      this.$router.push({
        name: "app-project-monthly",
        params: {
          project_id: this.$route.params.pid,
          year_month: moment().format("YYYY-MM"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status-clocking {
  font-weight: 400;
  font-size: 18px;
  line-height: 16px;
  border-radius: 3px;
  max-width: 80px;
  padding: 10px;
  text-align: center;
  &.status-in {
    color: #13d40f;
    background: rgba(106, 231, 62, 0.14);
  }
  &.status-brb {
    color: #4992ff;
    background: rgba(73, 146, 255, 0.14);
  }
  &.status-out {
    color: #ff4949;
    background: rgba(255, 73, 73, 0.14);
  }
}
</style>
